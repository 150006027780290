.spinner-container {
    position: relative;
}

.spinner-bg {
    background-image: url(../images/spinner_aperture_48.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.spinner {
    width: 50px;
    height: 50px;
    min-width: 48px;
    min-height: 48px;
    margin-left: auto;
    margin-right: auto;

    display: block;
    position: relative;
    &:extend(.spinner-bg);
}

.spinner--internal {
    position: absolute;
    background-position-x: 50%;
    background-position-y: 50%;
    top: 0;
    left: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    border-radius: 5px;
}

.cart-page-root,
.checkout-page-root {
    .spinner-container {
        min-height: 600px;

        .spinner {
            width: 100%;
            height: 100%;
        }
    }
}

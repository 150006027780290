.main-side-columns {
    --main: 1fr;
    --side: 35%;

    display: grid;
    grid-column-gap: 24px;
    grid: 'main side' / var(--main) var(--side);

    &.reverse-columns {
        grid: 'side main' / var(--side) var(--main);
        @media (--to-large) {
            grid: 'side' 'main';
        }
    }

    @media (--to-large) {
        grid: 'main' 'side';
    }

    .main-column {
        grid-area: main;
    }
    .side-column {
        grid-area: side;
    }
}

.full-page-wrapper {
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: 300px;
    grid-template-rows: 100vh;
    text-align: center;

    h2 {
        font-size: 26px;
        margin: 20px;
    }

    p {
        font-weight: 300;
        font-size: 18px;
    }
}

.cart-price {
    position: absolute;
    top: 0;
    right: 0;
}

.options-list {
    margin-left: 0;
    list-style: none;
    font-size: 20px;
    padding-left: 0;

    .cart-price {
        @media @from-medium {
            position: inherit;
            width: 80px;
            /* set width to align lead-time-info */
            text-align: right;
        }
    }

    h3,
    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 5px;
        text-transform: uppercase;
        width: 100%;
    }

    &>div {
        border-bottom: 1px solid var(--color-grey-02);

        &:last-child {
            border-bottom: 0;
        }

        &:first-child {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        &:not(.selected) {
            border-color: var(--color-grey-02);
            border-top: 0;
        }

        &.selected {
            background-color: var(--color-narvik);

            >button {
                font-weight: bold;
                color: var(--color-swamp);
            }

            .cart-price {
                color: var(--color-swamp);
                font-weight: normal;
            }
        }
    }

    .option-block {
        padding: 24px;
        font-size: 16px;
        width: 100%;
        border-top: 1px solid var(--color-grey-03);
        display: flex;
        font-weight: normal;
        flex-wrap: wrap;
        position: relative;

        @media @from-large {
            max-width: none;
        }

        .right-button a {
            margin-left: 0;
        }

        a {
            margin-left: 15px;
            text-transform: none;
            font-weight: normal;
        }

        @media @to-xxsmall {
            h4 a {
                margin-left: 8px;
            }
        }

        .btn-list {

            .btn,
            .btn--white {
                width: 100%;
                margin-bottom: 10px;

                @media @from-medium {
                    width: fit-content;
                    margin-right: 10px;
                }
            }
        }
    }

    .selected {
        .address {
            .main {
                font-weight: normal;
            }
        }
    }
}

.option-block,
.expandable-form {
    >div {
        width: 100%;
    }

    form {
        margin-top: 10px;

        .form-group {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .plain-text {
            line-height: 38px;
        }

        label {
            display: block;
            font-size: 14px;
            line-height: 15px;
        }

        .right-btn {
            text-align: right;
            margin: 0;
        }

        textarea {
            resize: none;
        }
    }

    .error-message {
        width: 100%;
        margin: 24px 0 0;
    }
}

.summary-item {
    .text-secondary;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    &.total {
        .text-primary;
        font-weight: bold;
    }

    &:last-of-type {
        padding-bottom: 0;
    }
}

.cart {
    .cart-section {
        margin-top: 40px;

        &:first-of-type {
            margin-top: 0;
        }

        .btn-text-and-icon {
            @media @to-medium {
                padding: 0 16px;
            }

            .hidden-xs {
                @media @to-small {
                    display: none;
                }
            }
        }

        .option-block {
            padding-left: 60px;
        }

        .option-block.store-finder {
            @media @to-medium {
                padding-left: 24px;
            }
        }

        .store-locator {
            margin-top: 0;
        }

        label {
            margin: 0 8px 0 0;
            font-weight: normal;
        }

        .delivery-mode-name {
            margin-right: 8px;
            width: 180px;
            /* set width to align lead-time-info */

            @media @to-medium {
                margin-bottom: 8px;
            }
        }

        .lead-time-info {
            font-weight: normal;

            @media @from-medium {
                width: 45%;
                margin: auto;
            }

            @media @to-small {
                padding-left: 0;
            }

            span {
                display: inline-block;
                color: var(--color-nevada);
            }
        }
    }

    #delivery-method-section .option-list-item-content {
        @media @to-medium {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 35px 1fr auto;

            input[type="radio"] {
                grid-row: 1/3;
            }
        }

        .cart-price {
            @media @to-medium {
                text-align: end;
                position: relative;
            }
        }
    }

    h2,
    legend {
        font-size: 14px;
        line-height: 110%;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--color-oxford);
        margin-bottom: 16px;
        display: inline-block;
        border-bottom: none;

        @media @to-medium {
            padding: 0 16px;
        }
    }

    .click-and-collect-text-link {
        text-decoration: underline;
    }

    .cart-summary {
        background-color: var(--color-white);
        padding: 24px;

        .error-message {
            margin: 0;
        }

        .cart-summary-block {
            padding: 16px 0;

            &:first-of-type {
                padding-top: 0;
                border-bottom: 1px solid var(--color-grey-02);
            }
        }

        .accept--terms-form {
            margin: 16px 0;
        }

        button {
            width: 100%;
            margin-top: 8px;
        }
    }

    .cart-page-entries {
        >.error-message {
            margin: 5px 0 25px 0;
            max-width: none;
        }
    }

    .product-item__error_msg {
        margin: 16px 0 0 0;
        color: var(--state-danger-text);
    }

    .expandable-form {
        background-color: var(--color-white);
        padding: 24px;
        font-size: 16px;

        h3 {
            .text-secondary;
            cursor: pointer;
            font-weight: bold;
            font-size: 16px;

            &:hover,
            &:focus {
                text-decoration: underline;
                outline: 0;
            }
        }

        &--active {
            h3 {
                color: #000;
                margin-bottom: 10px;
                position: relative;

                a {
                    margin-left: 15px;
                    text-transform: none;
                    font-weight: normal;
                    font-size: 14px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    a:focus {
        .product-item__image & {
            border: 0;
        }
    }

    .greeting-card {
        position: relative;

        @media @to-xxsmall {

            /* flytter overskrift lenger ned på minste devicer for å unngå overlapp med pris */
            &:not(.greeting-card--saved).expandable-form--active {
                padding-top: 48px;
            }
        }

        textarea {
            resize: none;
        }

        .option-list-item-content {
            padding: 0;
        }

        &.greeting-card--saved {
            .full-width {
                width: calc(100% - 55px);
            }

            label {
                font-size: 14px;
                margin-right: 10px;
                font-weight: bold;
            }

            span {
                font-weight: normal;
            }

            .cart-price {
                top: auto;
                bottom: 24px;
            }
        }

        .cart-price {
            position: absolute;
            top: 24px;
            right: 24px;
        }

        .greeting-card__instructions {
            margin: 10px 0 20px 0;
        }

        .error-message {
            margin: 0 0 20px;
        }

        .greeting-card__message-label {
            position: relative;
            display: flex;
            justify-content: space-between;

            .greeting-card__char-count {
                color: var(--color-nevada);
            }

            /* avoids overlap on smallest devices */
            @media @to-xsmall {
                flex-direction: column;

                .greeting-card__char-count {
                    width: 100%;
                    text-align: right;
                    margin-top: 4px;
                }
            }
        }
    }

    .store-locator .btn--white,
    .btn--white {
        background-color: var(--color-squeeze);
        border: 1px solid var(--color-iron);

        &:hover {
            background-color: var(--color-white);
        }
    }

    .infobox-centered {
        margin: 8px auto 32px;
        width: 100%;
        max-width: var(--medium-content-width);
    }

    line-height: 140%;

    @media @to-medium {
        .side-column {
            margin-top: 40px;
        }
    }

    @media @from-medium {
        .cart-section {
            margin-bottom: 16px;
        }

        .cart-order-summary {
            top: 92px;
            /* To offset the height of the navbar. Should find a more dynamic way of implementing this. */
            position: sticky;
        }
    }

    .empty-state .icon-cart {
        .generic-icon(28px);
    }

    .icon-delete {
        margin-left: 8px;
    }
}

body.page-cartPage {

    .header-category-bar,
    .hamburger-menu,
    .primary-navigation-desktop,
    .site__footer {
        display: none;
    }

    html & {
        min-height: 100%;
    }
}

.right-button {
    text-align: right;
}
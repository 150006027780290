.wishlist-page {
    .product-list {
        margin-top: 20px;
        margin-bottom: 40px;
        overflow: visible;
    }

    .wishlist-page__download-action {
        margin: 0;

        @media @from-large {
            font-size: 16px;
        }

        span {
            vertical-align: middle;
            display: inline-block;
        }
    }

    .addtocart {
        display: flex;
        align-items: center;

        span {
            margin-left: 8px;
        }
    }

    .wishlist-actions {
        @media @to-small {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        @media @from-small {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                padding-top: 0;
            }
        }

        @media @to-medium {
            margin: 16px 16px 0;
        }

        .addtocart {
            span {
                color: var(--color-oxford);

                @media @from-large {
                    font-size: 16px;
                }
            }
        }
    }

    .filter-sort-actions {
        display: grid;
        grid-template-columns: auto auto;
        margin-top: 16px;
        grid-gap: 8px;
        justify-items: start;

        &>*:last-child {
            margin-top: 16px;
        }

        @media @to-medium {
            margin-right: 16px;
            margin-left: 16px;
        }

        @media @desktop {
            grid-template-columns: auto auto 1fr;
            align-items: center;
            justify-items: end;

            &>*:last-child {
                margin-top: 0;
            }
        }
    }

    .filter-no-store {
        margin: 0;
    }
}

.pagelist {
    padding: 0;
    margin: 0 auto 36px;
    overflow: visible;
    max-width: var(--medium-content-width);

    li {
        display: flex;
        align-items: stretch;
        background-color: var(--color-white);
        list-style: none;
        margin-bottom: 4px;
        min-height: 56px;
        font-size: 16px;
        font-weight: bold;
        border-radius: var(--border-radius);

        &:hover {
            background-color: var(--color-haze);

            .title {
                text-decoration: underline;
            }
        }

        a {
            border: none;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        .content {
            padding: 16px;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;

            >.icon {
                .generic-icon(21px);
                width: 22px;
            }

            .inner_content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                padding-left: 12px;
                width: 100%;

                .title {
                    .primary-label;
                }

                .detail {
                    font-size: 14px;
                    font-weight: normal;
                    color: var(--color-nevada);
                    text-align: right;
                }
            }
        }

        form {
            width: 100%;
            padding: 16px;

            input {
                width: 100%;
                height: 48px;
            }

            .error-message {
                margin: 0 0 16px 0;
            }
        }
    }
}

li.flex-centered {
    justify-content: center;
    display: flex;
    margin: 0 16px;

    button {
        flex: 0 1 100%;
        text-align: center;
        background-color: transparent;
        border: none;
        font-weight: bold;
    }
}

.listoflists-page {
    .list-action-buttons {
        align-self: center;

        button {
            margin: 0 16px 0 0;
        }
    }
}
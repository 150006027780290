.outdated-browser {
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  .outdated-browser--popup {
    box-shadow: 0 0 40px black;
    background: #f4f4f5;
    border-radius: 8px;
    padding: 1em;
    max-width: 600px;
    margin: 1em;
    text-align: center;
    max-height: 90%;
    overflow: auto;

    img {
      max-width: 200px;
      margin: 2em;
    }

    details {
      text-align: left;
      border: 1px solid red;
      background: pink;
      padding: .5em;
      color: darkred;
      border-radius: 2px;

      summary {
        text-align: center;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
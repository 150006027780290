.page-articleCategory #page {
    margin: 0;
}

.search-category-page {
    .page-heading {
        margin: 0;
        padding: 80px;
        background-color: var(--color-apple-green);
        color: var(--color-swamp);

        h1 {
            font-family: var(--font-family-serif);
        }

        @media (--to-large) {
            margin-bottom: 16px;
        }
    }
}
.navigation-toggler {
    &:after {
        .icon-chevron-down;
        content: "";
        padding: 0;
        margin-left: 8px;
        display: inline-block;
    }

    &[aria-expanded=true]:after {
        .icon-chevron-up;
    }
}

.navigation-toggler--open {
    &:after {
        .icon-chevron-up;
    }
}

.navigation-toggler-white() {
    &:hover {
        &:after {
            .icon-chevron-down.white;
        }
    }

    &.navigation-toggler--open {
        &:after {
            .icon-chevron-up.white;
        }
    }
}
.expandable {
    background: var(--color-white);
    width: 100%;
    border-radius: 4px;

    &.expanded {
        .expandable__header {
            margin-bottom: 4px;
        }
    }
}

.expandable__header {
    font-size: 16px;
    padding: 16px 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    border-radius: 4px;

    svg {
        flex: 0 0 24px;
    }

    .expandable__title {
        width: calc(100% - 24px);
    }

    &:focus,
    &:hover {
        span {
            text-decoration: underline;
        }
    }
}

.expand-button {
    min-width: 21px;
    min-height: 21px;
    display: grid;
    place-content: center;
    border-radius: 50%;
    --icon-size: 8px;

    &.light {
        background-color: var(--color-grey-02);
    }

    &.dark {
        background-color: var(--color-white);
    }
}

.expandable__theme--dark {
    background-color: var(--site-background);

    .expandable__title {
        .text-secondary;
        font-size: 13px;
        text-transform: uppercase;
    }
}

.expandable__icon--left {
    flex-direction: row-reverse;

    svg {
        margin: 0 16px;
    }
}

.expandable__icon-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.cms-facts-box {
    background-color: var(--color-cream-brulee);
    padding: 48px;
    margin-top: 56px;

    @media (--to-medium) {
        padding: 24px;
        margin-top: 40px;
    }

    p {
        margin-bottom: 8px;
    }
}
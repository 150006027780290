@layer normalize {
    /*
    1. Set default font family to sans-serif.
    2. Prevent iOS and IE text size adjust after device orientation change,
    without disabling user zoom.
    */

    html {
        /* 1 */
        font-family: sans-serif;
        /* 2 */
        -ms-text-size-adjust: 100%;
        /* 2 */
        -webkit-text-size-adjust: 100%;
    }

    /*
    Remove default margin.
    */

    body {
        margin: 0;
    }

    /*
    Forms
    ==========================================================================

    1. Correct color not being inherited.
    Known issue: affects color of disabled elements.
    2. Correct font properties not being inherited.
    3. Address margins set differently in Firefox 4+, Safari, and Chrome.
    */

    button,
    input,
    select,
    textarea {
        /* 1 */
        color: inherit;
        /* 2 */
        font: inherit;
        /* 3 */
        margin: 0;
    }

    /*
    Tables
    ==========================================================================

    Remove most spacing between table cells.
    */

    table {
        border-collapse: collapse;
        border-spacing: 0;
        text-align: left;
    }

    td,
    th {
        padding: 0;
    }
}
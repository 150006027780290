@layer normalize {

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 0px;
		padding: 0px;
	}
}
.check-giftcard-balance-page {

    .check-giftcard-balance-cards {
        display: flex;
        justify-content: center;
        gap: 28px;


        @media (--to-medium) {
            gap: 8px;

            img {
                max-width: 150px;
            }
        }
    }
}

.giftcard-form {
    .form-group:first-child {
        margin-top: 16px;
    }

    p {
        margin: 24px 0 0 0;
    }
}
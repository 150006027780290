.product-item {
    padding: 16px;
    position: relative;
    background-color: var(--color-white);


    @media @to-medium {
        padding-right: 48px;
        /* make space for .product-tools */
    }

    .product__image-container {
        border-bottom: none;
        display: block;
        margin: 0 auto 16px;
        max-width: none;
        min-height: 200px;
        width: auto;

        @media @to-small {
            width: 60px;
            min-height: unset;
            margin: 0;
        }

        img {
            height: 200px;
            max-height: 200px;
            width: 100%;
            object-fit: contain;

            @media @to-small {
                width: 60px;
                height: 175px;
            }
        }

        .expired-img {
            width: 90%;
            left: 5%;
        }
    }

    .link-text {

        &:focus,
        &:active,
        &:hover {
            border-bottom: 1px solid var(--color-oxford);
        }
    }

    .info-container {
        min-height: 125px;
        margin-bottom: 10px;

        @media @to-small {
            min-height: unset;
        }
    }

    .image-and-info-container {
        @media @to-small {
            display: flex;
            column-gap: 8px;
        }
    }

    .product-stock-status {
        margin-top: 16px;
    }

    .product-item__stock-status {
        .text-secondary;
        margin: 8px 0 21px;
    }

    .product__category-name {
        margin: 0 0 4px;
    }

    .product__name {
        font-size: 18px;
        display: inline;

        a {
            .text-primary;
        }
    }

    .product__price {
        font-size: 28px;
    }

    .product__code {
        margin: 8px 0;
    }

    .product-tools {
        top: 8px;
        right: 8px;
    }

    .product-item-review {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid var(--color-grey-02);
    }

    .entry-creation-date {
        margin-top: 16px;
        color: var(--color-nevada);
        font-size: 12px;
    }
}

.product-item__cost_per_unit {
    display: block;
    font-size: 14px;
}

.product-item__image--expired {
    img {
        opacity: 0.5;
    }
}

.image-and-info-container {
    @media @to-small {
        display: flex;
        column-gap: 8px;
    }

    .product-item__image {
        @media @to-small {
            width: 90px;
            min-height: unset;
            margin: 32px auto 0;
        }

        img {
            @media @to-small {
                width: 90px;
                height: 175px;
                object-fit: contain;
            }
        }
    }

    .price-panel {
        margin: 16px 0;
        width: 100%;

        .amount {
            color: var(--color-nevada);
            font-weight: 600;
            text-transform: lowercase;
            margin-left: 8px;
        }
    }

    .product-price-expired {
        padding: 16px 0;
    }
}

.rating-star--active,
.rating-star--not-active {
    background-repeat: no-repeat;
    background-size: 24px 24px;
}

.rating-star--not-active {
    .generic-icon(24px);
    .icon-set-backgound(url('../images/star-inactive.svg'));
}

.rating-star--active {
    .generic-icon(24px);
    .icon-set-backgound(url('../images/star-active.svg'));
}

.rating-star--active-1 {
    .generic-icon(24px);
    .icon-set-backgound(url('../images/star-active-1.svg'));
}


.rating-star--active-2 {
    .generic-icon(24px);
    .icon-set-backgound(url('../images/star-active-2.svg'));
}


.rating-star--active-3 {
    .generic-icon(24px);
    .icon-set-backgound(url('../images/star-active-3.svg'));
}


.rating-star--active-4 {
    .generic-icon(24px);
    .icon-set-backgound(url('../images/star-active-4.svg'));
}


.rating-star--active-5 {
    .generic-icon(24px);
    .icon-set-backgound(url('../images/star-active-5.svg'));
}

// TODO: move this into product-review.css
.product-item__rating-stars {
    color: var(--color-oxford);
    direction: rtl;
    display: inline-block;

    /* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
	 follow these rules. Every browser that supports :checked also supports :not(), so
	 it doesn’t make the test unnecessarily selective */

    &:not(:checked)>input:not(.form-control) {
        .sr-only;
    }

    &:not(:checked)>label {
        cursor: pointer;
        margin-right: calc(var(--icon-size, 24px) / 3 * 2);
    }

    &:not(:checked)>label {
        .rating-star--not-active;
    }

    &>input:not(:disabled) {

        &~label:hover,
        &~label:hover~label {
            .rating-star--active;
        }

        &:checked {

            &+label:hover,
            &+label:hover~label,
            &~label:hover,
            &~label:hover~label {
                .rating-star--active;
            }
        }
    }

    &>label:hover~input:checked~label {
        .rating-star--active;
    }

    &>input:checked~label {
        .rating-star--active;
    }
}

.product-item-quantity-and-sum+.product-item__entry-status {
    margin-top: 8px;
}

.product-item__entry-status {
    margin-bottom: 0;
    padding-top: 8px;
    border-top: 1px solid var(--color-iron);
    font-size: 14px;
}
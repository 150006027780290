:root {
    --border-radius: 4px;

    --medium-content-width: 748px;

    /* Farger */

    --site-background: var(--color-grey-01-5);
    --color-separator: var(--color-iron);

    /* Primærfarger */

    --color-swamp: #002025;
    --color-maple: #780103;
    --color-mint-green: #cfe9de;

    /* Bakgrunnsfarger */

    --color-oxford: #334d51;
    --color-nevada: #66797c;
    --color-oslo-grey: #808f92;
    --color-iron: #ccd2d3;
    --color-grey-03: #dfe0e1;
    --color-grey-02: #e9eaeb;
    --color-grey-01-5: #f4f4f5;
    --color-grey-01: #f8f9fa;
    --color-white: #ffffff;
    --color-limeade-focus-dark: #6e7700;
    --color-aqua-deep: #005046;
    --color-apple-green: #dcf2eb;
    --color-narvik: #e8f7f2;
    --color-squeeze: #f9fdfc;
    --color-haze: #f0f2f2;
    --color-azalea: #f8cfd7;
    --color-wisp-pink: #fce7eb;
    --color-chablis: #ffeff0;
    --color-warning-red: #cc0a22;

    /* Sekundærfarger */

    --color-cinderella: #ffc4bf;
    --color-cherry: #810b34;
    --color-zest: #de8122;
    --color-porsche: #e7a46e;
    --color-mac-and-cheese: #ffc17d;
    --color-flesh: #ffcaa4;
    --color-egg-white: #fff5bf;
    --color-old-lace: #f9eee5;
    --color-celtic: #14331f;
    --color-deep-sea-green: #085248;
    --color-blue-stone: #006458;
    --color-chinook: #8accb0;
    --color-tiara: #c9d5d2;

    /* Tertiærfarger */

    --color-berry-red: #ab0316;
    --color-limeade: #939f00;
    --color-solid-red: #ff0000;
    --color-supernova: #ffc600;
    --color-toledo: #45011d;
    --color-cream-brulee: #ffdea1;
    --color-spray: #aaf2c2;
    --color-grass: #1aa849;

    /*********************/
    /* Styling variables */
    /*********************/

    --btn-link-disabled-color: var(--color-oslo-grey);

    --state-info-text: var(--color-deep-sea-green);
    --state-info-bg: var(--color-narvik);
    --state-info-border: 2px solid var(--color-mint-green);

    --state-danger-text: var(--color-warning-red);
    --state-danger-bg: var(--color-wisp-pink);
    --state-danger-border: 2px solid var(--color-warning-red);

    --header-shadow: 0 2px 10px rgba(185, 185, 185, 0.2);
    --box-shadow: 0px 0px 1.06402px rgba(0, 0, 0, 0.0161557), 0px 0px 3.57381px rgba(0, 0, 0, 0.0238443), 0px 0px 16px rgba(0, 0, 0, 0.05);

    --secondary-text-color: var(--color-oxford);

    --color-primary: var(--color-swamp);
    --color-paragraph: var(--color-swamp);
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .site__header,
    .related-articles,
    .site__footer,
    .helpButton,
    .no-print {
        display: none !important;
    }

    .label {
        border: none;
    }

    a[href]:after {
        content: none;
    }
}

.section__inner-container {
	max-width: 1400px;
	--section-padding: 0;
	display: grid;
	margin-left: auto;
	margin-right: auto;

	padding: 0;
	padding-left: calc(var(--section-padding) + env(safe-area-inset-left));
	padding-right: calc(var(--section-padding) + env(safe-area-inset-right));

	@media @to-medium {
		width: auto;
		margin: 0;
	}

	@media @from-medium {
		--section-padding: 24px;
	}

	@media @from-large {
		--section-padding: 40px;
	}

	@media @from-xlarge {
		--section-padding: 56px;
	}

	@media print {
		--section-padding: 0;
	}
}

.section {
	.section-spacing-small;
}

.section--divider {
	border-top: 1px solid var(--color-separator);
}
.rating-and-comments {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: stretch;
}

.product-item__rating-stars {
    display: block;
    text-align: center;
}

.product-review__comment {
    resize: vertical;
}
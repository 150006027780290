/*
 Image Text component - skal vises horisontalt på tablet/desktop,
 og vertikalt på mobil
 */
.cms-image-text {
    background-color: var(--color-grey-02);
    display: flex;
    align-items: center;
    flex-direction: column;
    .section-spacing-small;

    &.imageOnRight {
        flex-direction: column-reverse;

        @media @to-medium {
            flex-direction: column;
        }
    }

    .imagePart {
        img {
            margin: 0;
        }
    }

    .article__image-credits {
        display: none;
    }

    .textPart {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 48px;

        @media @to-medium {
            padding: 24px;
        }

        .content {
            margin: 0;
        }

        .title {
            font-family: var(--font-family-serif);
        }
    }

    @media @from-medium {

        flex-direction: row;

        &.imageOnRight {
            flex-direction: row-reverse;
        }

        &.noImage {
            .textPart {
                width: 100%;
            }
        }

        .imagePart,
        .textPart {
            width: 50%;
        }
    }
}
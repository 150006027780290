.page-heading {
    margin: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;

    @media @from-large {
        margin: 32px 16px;
    }

    .page-heading-top-row {
        width: 100%;
        display: flex;
        align-items: center;

        &> :first-child,
        &> :last-child {
            flex: 1;
        }

        @media @to-medium {
            align-items: normal;
            flex-flow: column;

            .back-link__container {
                margin-bottom: 8px;
            }
        }
    }

    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

a.page-back-link {
    color: var(--color-oxford);
    font-size: 14px;

    &:before {
        content: '';
        float: left;
        margin-right: 8px;
        margin-top: 4px;
        .icon-chevron-left;
    }
}

.page-sub-header {
    .text-secondary;
    text-align: center;
    margin-top: 16px;

    @media @to-small {
        margin-top: 8px;
    }
}

/* home page should not have top margin */
body.page-homepage {
    section {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.section-spacing {
    margin-bottom: 32px;

    @media @from-medium {
        margin-bottom: 56px;
    }
}

.section-spacing-small {
    margin-bottom: 24px;

    @media @from-large {
        margin-bottom: 32px;
    }
}

.medium-content {
    max-width: var(--medium-content-width);
    margin: 0 auto;
}

section {
    .section-spacing;

    @media @to-medium {
        padding: 24px;
    }

    @media @from-medium {
        padding: 48px;
    }

    &.centered {
        margin: 0 auto;

        h2,
        h3,
        p {
            text-align: center;
        }

        p {
            max-width: var(--medium-content-width);
            margin: 0 auto 16px auto;
        }
    }

    &.transparent {
        background-color: transparent;
    }

    &.white {
        background-color: var(--color-white);
        width: 100%;
    }

    &.form-section {
        border-top: 1px solid var(--color-grey-02);

        &.top {
            border-top: none;
        }
    }

    h3 {
        .margin-bottom-medium;
    }

    form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .form-row {
            display: flex;
            flex-direction: column;

            @media @from-medium {
                flex-direction: row;
            }
        }

        .btn-primary {
            margin-left: 16px;
        }
    }
}

.margin-bottom-medium {
    margin-bottom: 16px;
}

.page-homepage main {
    @media @to-medium {

        .section-4,
        .section-5 {
            .section__inner-container {
                padding: 0 16px;
            }
        }
    }
}

.page-checkout-login {
    #loginForm {
        margin-bottom: 20px;
    }
}

section.login-section {
    padding-top: 0;

    @media @from-medium {
        max-width: var(--screen-xs-max);

        p,
        .form-group,
        .error-message {
            margin: 0 0 32px 0;
        }
    }
}
.page-productDetails {
    .product__details {
        background-color: var(--color-white);
        box-shadow: var(--box-shadow);
        min-height: 1000px;
        margin-top: 16px;

        @media @from-large {
            margin-top: 32px;
        }

        @media print {
            box-shadow: none;
            margin: 0;
        }
    }

    .product__year-footnote {
        font-weight: normal;
        padding-left: 3px;
        vertical-align: -3px;
    }

    .product__stiler {
        margin: 0;
        overflow: hidden;
        position: relative;

        @media @to-medium {
            border-left: 0;
        }
    }

    .product__details--top {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 32px;

        @media @from-medium {
            flex-direction: row;
        }

        @media @to-medium {
            padding: 0;
            padding-top: 32px;
        }

        @media @from-large {
            padding: 64px;
        }

        .volume-and-cost_per_unit {
            padding-top: 16px;

            @media @to-medium {
                padding-top: 8px;
            }
        }
    }

    /************************ PRODUCT TABS **********************/
    .product__layout-wrapper-3 {
        background-color: #fafafa;
        padding: 0;

        .product__all-info {
            .product-tab {
                position: relative;

                .expanded {
                    margin-bottom: 0;
                }

                .tab-button-unselected {
                    color: var(--color-oxford);
                    font-weight: normal;
                }
            }

            .product-accordion {
                display: none;

                @media @to-small {
                    display: flex;
                    flex-direction: column;
                    padding: 24px;
                }
            }

            .product-accordion-header {
                margin-top: 4px;
                padding: 0 16px;
            }

            .product-tab-headers {
                display: flex;

                @media @to-small {
                    display: none;
                }
            }

            .product-tab__width {
                width: 185px;

                @media @to-medium {
                    width: 150px;
                }
            }

            .accordion__panel {
                background-color: var(--color-white);
                display: none;
                padding: 16px;

                &.selected {
                    display: block;
                }

                .accordion__header-print {
                    display: none;
                }

                @media @from-medium {
                    padding: 24px;
                }

                @media @from-large {
                    padding: 56px;
                    min-height: 400px;
                }
            }

            .accordion__panel[aria-hidden='false'] {
                display: block;
                left: 0;
                width: 100%;
                margin: 0;
            }

            .product__tab-list {
                .text-secondary;
                list-style: none;
                line-height: 120%;
                margin: 0;
                padding: 0;

                li {
                    display: flex;
                    padding-bottom: 16px;

                    span:first-child {
                        font-weight: bold;
                        flex: 0 0 145px;
                        padding-right: 10px;

                        @media @to-medium {
                            flex: 0 0 120px;
                        }
                    }
                }
            }
        }

        .similar-products {
            min-height: 100px;

            .message {
                margin: 0;
                padding-bottom: 16px;

                @media @to-medium {
                    padding: 16px;
                }
            }

            .product-list {
                gap: 8px;
            }

            .product-item {
                border: 1px solid var(--color-iron);
            }
        }
    }
}

.product-tools {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 1;
    display: flex;
    flex-direction: column;

    @media @to-medium {
        top: 8px;
        right: 8px;
    }
}

/****************   PRODUCT IMAGE   ***************/
.product__image-container {
    position: relative;
    text-align: center;

    img {
        width: 240px;
        height: 300px;
        object-fit: contain;
    }

    .expired {
        opacity: 0.5;
    }

    @media @from-large {
        margin: 0 56px;
    }

    @media @from-xlarge {
        img {
            width: 300px;
            height: 380px;
        }
    }

    &.zoomable {

        img {
            /* touch-action: none; */

            @media @to-medium {
                width: 50%;
            }

            @media @to-small {
                width: 100%;
            }
        }

        .zoom {
            z-index: 1;
            background: #fff;
            position: absolute;
            overflow: hidden;
            inset: 0;
            pointer-events: none;
            object-fit: none;

            left: 0%;

            @media @to-medium {
                left: 25%;
                display: none;
            }

            @media @from-medium {
                left: 100%;
                box-shadow: 0 0 5px 0 #00000050;
                border-radius: var(--border-radius);
            }

            @media @from-large {
                left: calc(100% + 56px);

            }
        }
    }
}

.expired-img {
    position: absolute;
    top: 50%;
    background-color: var(--color-white);
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-oxford);
}

/****************   PRODUCT MAIN INFO   ***************/
.product__category-name {
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--color-nevada);
    margin: 10px 0;
    letter-spacing: 1px;
}

.product__name {
    .text-primary;
    font-weight: normal;
    font-family: var(--font-family-serif-ingress);
    line-height: 120%;
}

.product__code {
    font-size: 12px;
    line-height: 120%;
    color: var(--color-nevada);
    margin: 5px 0;
}

.product__district {
    .text-primary;
    font-size: 13px;
}

.product__price {
    .text-primary;
    font-weight: bold;
    font-size: 32px;
    line-height: 100%;
    white-space: nowrap;
}

.volume-and-cost_per_unit {
    color: var(--color-nevada);
    font-size: 14px;
    display: block;

    .amount {
        font-weight: 600;
        text-transform: lowercase;
        padding-right: 4px;
    }
}

.product-price-expired {
    font-size: 13px;
    font-style: italic;
}

.product__layout-wrapper {
    width: 100%;
    padding: 16px;
    max-width: 625px;

    @media @from-xsmall {
        padding: 24px;
    }

    @media @from-medium {
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .product-details-main {
        width: 100%;
        padding-bottom: 16px;

        .product__name {
            font-size: 26px;
            text-align: left;
        }

        .product__region {
            margin-bottom: 16px;

            a {
                font-size: 13px;
                text-decoration: none;
                color: var(--color-nevada);

                &:first-child {
                    .text-primary;
                }
            }
        }

        .product__taste {
            .text-secondary;
            font-size: 16px;
            line-height: 120%;
            margin: 0;
        }

        @media @from-medium {

            .product__category-name,
            .product__name {
                margin-right: 40px;
                /* make space for the .product-tools */
            }
        }

        @media @from-large {
            .product__name {
                font-size: 32px;
            }

            .product__taste {
                font-size: 18px;
            }
        }
    }

    .product__price-buy-stock__container {
        .btn-tertiary {
            span {
                margin-left: 8px;
            }
        }

        .info-message {
            margin: 16px 0;

            @media @from-large {
                margin: 24px 0;
            }
        }
    }

    .product__price-and-buy {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @media @from-medium {
            flex-wrap: nowrap;
        }

        &>* {
            margin-bottom: 28px;

            @media @to-medium {
                margin-bottom: 16px;
            }
        }

        .product__price-and-buy__price-wrapper {
            margin-right: 56px;
        }

        .product__unit-price {
            padding-left: 5px;
        }
    }

    .product-stock-status {
        @media @from-large {
            grid-template-columns: 22px 1fr 22px 1fr;
        }
    }

    .product-email-reminder {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;

        .info-message .message-text {
            padding-left: 0;
            padding-right: 8px;
        }
    }
}

.product__print {
    display: none;

    a:hover,
    a:focus {
        .icon-print {
            text-decoration: none;
        }
    }
}

.product__validation-error {
    color: var(--state-danger-text);
    margin-top: 15px;
    font-size: 16px;
}

/****************   ProductMiniCard   ***************/
.product-mini-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 16px;

    @media @from-medium {
        gap: 40px;
    }

    /* Left-aligned text for all sizes, image floats to left on small devices */
    &.product-mini-card__left {
        align-items: start;

        @media @to-medium {
            flex-direction: row;
        }
    }

    /* Centered for all sizes */
    &.product-mini-card__center {
        text-align: center;
    }

    .product__category-name {
        margin: 0 0 10px 0;
    }

    .product__name {
        font-size: 16px;
        margin: 0 0 16px 0;

        @media @from-small {
            font-size: 18px;
        }
    }

    img {
        max-width: 90px;
        max-height: 105px;
        margin: 0 10px;

        @media @from-medium {
            max-height: 200px;
            max-width: 140px;
        }
    }
}
.list-content {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    align-self: center;

    .content-align--left & {
        align-items: flex-start;
    }
}

.list-action-buttons {
    display: flex;
    align-items: baseline;

    button {
        background-color: transparent;
        border: none;
        margin: 0 0 0 16px;
        padding: 0;
    }

    .default-link {
        margin-right: 16px;
        font-size: 14px;
        font-weight: bold;
    }
}

.tooltip {
    /* container */
    position: relative;

    .tooltiptext {
        display: inline-block;
        visibility: hidden;
        width: 90px;
        bottom: 32px;
        left: 50%;
        /* Use half of the width to center the tooltip */
        margin-left: -45px;
        position: absolute;
        z-index: 1;
        background-color: var(--color-oxford);
        color: var(--color-white);
        text-align: center;
        padding: 8px 16px;
        border-radius: 4px;

        @media (--to-medium) {
            /* hiding as there is no hover and the tooltip extends beyond the window screen on mobile*/
            display: none;
        }
    }

    .tooltiptext::after {
        content: ' ';
        position: absolute;
        /* At the bottom of the tooltip */
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: var(--color-oxford) transparent transparent transparent;
    }

    &:hover .tooltiptext {
        visibility: visible;
    }
}

.order-confirmation {
    .spinner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    p {
        text-align: center;
        max-width: 80ch;

        &:first-of-type {
            margin-top: 32px;
        }
    }

    .info-message {
        max-width: 500px;
        margin: 40px auto;
    }
}
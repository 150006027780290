:root {
    /* These variables are just for intellisense help, it's the @custom-media that are actually used */

    /* Prefer to use this one. */
    --desktop: width >= 768px;

    /* Try not to use this one, prefer to use @desktop */
    --mobile: width < 768px;

    /* These are the older media queries, try not to use them */
    --screen-xs-min: 320px;
    --screen-sm-min: 769px;
    --screen-md-min: 1024px;
    --screen-lg-min: 1440px;

    --screen-xs: 320px;
    --screen-sm: 769px;
    --screen-md: 1024px;
    --screen-lg: 1440px;

    --screen-xs-max: 768px;
    --screen-sm-max: 1023px;
    --screen-md-max: 1439px;

    /*                                    GA4 users | usages in .less files */
    --from-xxsmall: min-width: 321px; /*  98%       |  0 */
    --from-xsmall: min-width: 464px; /*   19%       | 12 */
    --from-small: min-width: 568px; /*    19%       |  4 */
    --from-medium: min-width: 768px; /*   18%       | 72 */
    --from-large: min-width: 1024px; /*   15%       | 62 */
    --from-xlarge: min-width: 1440px; /*  10%       | 15 */

    /*                                    GA4 users | usages in .less files */
    --to-xxsmall: max-width: 320px; /*     2%       |  3 */
    --to-xsmall: max-width: 463px; /*     81%       | 10 */
    --to-small: max-width: 567px; /*      81%       | 35 */
    --to-medium: max-width: 767px; /*     82%       | 68 */
    --to-large: max-width: 1023px; /*     85%       | 19 */
    --to-xlarge: max-width: 1439px; /*    90%       |  0 */
}

@custom-media --desktop (width >= 768px);
@custom-media --mobile (width < 768px);

@custom-media --from-xxsmall (min-width: 321px); /*  98%       |  0 */
@custom-media --from-xsmall (min-width: 464px); /*   19%       | 12 */
@custom-media --from-small (min-width: 568px); /*    19%       |  4 */
@custom-media --from-medium (min-width: 768px); /*   18%       | 72 */
@custom-media --from-large (min-width: 1024px); /*   15%       | 62 */
@custom-media --from-xlarge (min-width: 1440px); /*  10%       | 15 */

@custom-media --to-xxsmall (max-width: 320px); /*     2%       |  3 */
@custom-media --to-xsmall (max-width: 463px); /*     81%       | 10 */
@custom-media --to-small (max-width: 567px); /*      81%       | 35 */
@custom-media --to-medium (max-width: 767px); /*     82%       | 68 */
@custom-media --to-large (max-width: 1023px); /*     85%       | 19 */
@custom-media --to-xlarge (max-width: 1439px); /*    90%       |  0 */

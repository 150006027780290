.placeholder-text {
	background:  linear-gradient(
		to left,
		var(--placeholder-text-color-1, #88888822),
		var(--placeholder-text-color-2, #88888844) 25%,
		var(--placeholder-text-color-1, #88888822) 50%,
		var(--placeholder-text-color-2, #88888844) 75%,
		var(--placeholder-text-color-1, #88888822) 100%);
	background-size: 200% 100%;
	background-repeat: repeat-x;
	animation: placeholder-text-background .5s linear infinite;
	height: 1em;
	width: 4em;
	display: inline-block;
	border-radius: .2em;
	cursor: default;
}

@keyframes placeholder-text-background {
    from {
        background-position: 100% 0%;
    }
    to {
        background-position: 0% 0%;
    }
}

.full-width {
  max-width: none;
  padding: 0;
}

.top-module__banner {
  width: 100%;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  background-color: var(--color-aqua-deep);
  padding: 24px 0;
  .section-spacing;

  .top-module__container {
    margin: 0 auto;
    padding: 48px;
    background-color: var(--color-aqua-deep);

    &--2 {
      width: 1007px;

      @media @to-large {
        width: auto;
      }
    }
  }

  .top-module__title {
    font-size: 46px;
    color: var(--color-apple-green);
    font-family: var(--font-family-serif);
    padding-bottom: 40px;
  }

  .top-module__subtitle {
    font-size: 24px;
    color: var(--color-apple-green);
  }

  .top-module__links {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &--2 {
      justify-content: center;
    }
  }

  .top-module__link {
    box-shadow: inset 0 0 0 1px var(--color-narvik);
    background-color: rgba(232, 247, 242, 0.1);
    width: 277px;
    display: flex;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      box-shadow: inset 0 0 0 2px var(--color-narvik);
    }
  }

  .icon-link {
    color: var(--color-narvik);
    border-bottom: none;
    font-size: 18px;
    line-height: 28px;
    padding: 28px 24px;
    width: 100%;
    display: inline-block;

    &:hover>.link__name {
      border-bottom: 1px solid;
    }
  }

  .top-module__line {
    height: 7px;
    width: 100%;
    margin: 8px 0;
    background-color: var(--color-narvik);
  }

  .top-module__line--thin {
    height: 2px;
    width: 100%;
    background-color: var(--color-narvik);
  }

  &.wine-red-background {
    padding: 0;

    .top-module__container {
      opacity: .9;
      background-color: var(--color-toledo);
    }

    .top-module__title {
      color: var(--color-chablis);
    }

    .top-module__subtitle {
      color: var(--color-chablis);
    }

    .top-module__link {
      box-shadow: inset 0 0 0 1px var(--color-chablis);
      background-color: var(--color-toledo);
      opacity: 1;

      &:hover {
        box-shadow: inset 0 0 0 2px var(--color-chablis);
      }
    }

    .icon-link {
      color: var(--color-chablis);
    }

    .top-module__line--thin,
    .top-module__line {
      display: none;
    }
  }

  @media (max-width: @screen-sm) {
    .top-module__links {
      flex-direction: column;
    }

    .top-module__link {
      width: 100%;
      margin-bottom: 24px;

      &:last-child {
        margin: 0;
      }
    }
  }

  @media (max-width: @screen-md) {
    .top-module__container {
      margin: 64px 16px;
      padding: 32px 16px;
    }

    .top-module__link {
      margin-right: 16px;
    }

    .top-module__title {
      font-size: 30px;
      line-height: 36px;
      padding-bottom: 32px;
    }

    .icon-link {
      font-size: 16px;
      line-height: 20px;
    }

    .top-module__subtitle {
      font-size: 20px;
    }
  }
}
.dialog-split {
    display: grid;
    height: 100%;
    width: 100%;

    .dialog-main {
        background-color: var(--color-white);
        padding: 16px;
        overflow-y: auto;
        border-radius: 0 0 10px 10px;
    }

    @media (--to-small) {
        height: 100%;

        .dialog-main {
            height: auto;
            overflow: auto;
            padding: 20px 10px 50px 10px;
        }

        overflow: scroll;
    }

    @media (--from-medium) {
        grid-template-columns: 1fr 1fr;
    }
}
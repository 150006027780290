.cms-downloadable-file {
    background-color: var(--color-apple-green);
    padding: 0;
    margin: 56px 0;

    @media @to-medium {
        margin-top: 40px;
    }
}

.cms-downloadable-file--list .article-card {
    background-color: var(--color-apple-green);
}

.image-text-component-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    &:hover {
        .downloadable-file-info .downloadable-file-info-wrapper h3 span {
            border-bottom: 1px solid;
        }
    }

    .cms-downloadable-file--list & {
        height: 100%;

        @media @to-xsmall {
            height: 100%;
            max-height: 100%;
        }
    }

    img {
        margin: 0;
        height: 327px;
        object-fit: cover;

        .cms-downloadable-file--list & {
            height: 233px;

            @media @from-large {
                height: 233px;
            }
        }

        @media @to-medium {
            min-height: 208px;
            object-position: center;
        }

        @media @from-large {
            height: 383px;
        }
    }

    .cms-downloadable-file-img {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        width: 100%;
        height: 327px;

        .cms-downloadable-file--list & {
            padding: 24px 24px 0;
            min-width: 100%;
            height: 258px;

            @media @from-xsmall {
                max-height: 258px;
            }
        }

        @media @from-medium {
            min-width: 33.3%;
            flex: 1;
        }

        @media @from-large {
            height: 383px;
        }
    }

    .downloadable-file-link--hover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .downloadable-file-info-wrapper {
        .cms-downloadable-file--list & {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .downloadable-file-info {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 56px;
        flex-direction: column;
        flex-basis: 100%;

        .cms-downloadable-file--list & {
            padding: 24px;
            height: 117px;
        }

        @media @from-medium {
            flex: 2;
        }

        @media @to-xsmall {
            padding: 24px;
        }
    }

    h3 {
        font-family: var(--font-family-serif);

        .cms-downloadable-file--list & {
            font-size: 16px;
            margin-bottom: 0;
        }

        span {
            padding: 0;
            .text-primary;
        }
    }

    .downloadable-file-info-link {
        display: flex;
        align-items: center;

        .cms-downloadable-file--list & {
            justify-content: center;
        }
    }

    span {
        font-weight: bold;
        padding-right: 12px;
        color: var(--color-oxford);
    }
}
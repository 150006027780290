.theme-module {
    width: 100%;
    max-width: @screen-lg;
    padding: 0;
    background-color: var(--color-apple-green);
    display: flex;
    flex-direction: column;

    @media (--from-medium) {
        width: 100%;
        flex-direction: row;

        &.imageOnRight {
            flex-direction: row-reverse;
        }
    }

    .theme-module__link {
        width: 100%;

        @media (--from-medium) {
            width: 50%;
        }
    }

    .theme-module__content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        @media (--from-medium) {
            width: 50%;
        }
    }

    .theme-module__image {
        width: 100%;
        height: 100%;
        padding: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            padding-top: 0;
            margin-top: 0;
        }
    }

    .theme-module__link:hover+.theme-module__content,
    .theme-module__content-link:hover {
        .theme-module__title {
            border-bottom: 1px solid;
        }
    }

    .theme-module__content-link {
        color: var(--color-swamp);
        margin: 0;
        padding: 24px;
        text-align: center;
        display: inline;

        .theme-module__title {
            font-family: var(--font-family-serif);
            font-weight: bold;
            font-size: 24px;
            min-height: 26px;
            line-height: 120%;
            margin-bottom: 6px;
            display: inline;

            &:hover {
                border-bottom: 1px solid;
            }

            @media (--from-medium) {
                font-size: 28px;
                min-height: 36px;
            }
        }

        .theme-module__text {
            font-size: 16px;
            line-height: 160%;
            padding: 16px 0;

            @media (--from-xlarge) {
                font-size: 18px;
                padding: 24px 0;
            }
        }

        .theme-module-link {
            font-size: 16px;
            border-bottom: 1px solid;
            color: var(--color-blue-stone);
            min-height: 18px;
            width: max-content;
            display: inline;

            @media (--from-medium) {
                font-size: 18px;
                min-height: 20px;
            }

            &:hover {
                border-bottom: 2px solid;
            }
        }

        @media (--from-medium) {
            display: inline;
        }

        @media (--from-large) {
            padding: 56px;
        }
    }
}
.grape {
  display: block;
  background: white;
  width: min(100%, 750px);
  justify-self: center;
  scroll-margin-top: var(--header-height, 0);
  --grape-padding: 16px;
  padding-bottom: var(--grape-padding);

  li:has(>&) {
    grid-column: 1 / -1;

  }

  @media (--desktop) {
    --grape-padding: 40px;

    /* This is a hack to reduce the gap between the grape entries */
    margin-block: -12px;
  }

  details {
    scroll-margin-top: inherit;


    &>* {
      margin-inline: var(--grape-padding);
    }

    summary {
      padding-block: var(--grape-padding) 0;
      display: flex;
      flex-direction: column;
      list-style: none;
      position: relative;

      &::marker,
      &::-webkit-details-marker {
        /* hack for safari */
        display: none;
      }

      h2 {
        font-family: var(--font-family-serif);
        font-weight: bold;
        font-size: var(--font-size-h3);
        margin-bottom: 8px;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &:has(>p) {
      summary {
        cursor: pointer;

        h2 {
          padding-right: 40px;
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          background: var(--color-grey-02) url(../../../images/svg/icon-chevron-down.svg) no-repeat center;
          background-size: 14px;
          right: var(--grape-padding);
          top: var(--grape-padding);
        }

        @media (--desktop) {
          padding-right: calc(var(--grape-padding) * 3);
          margin-right: 0;

          h2 {
            padding-right: 0;
          }

          &::after {
            top: calc(50% - 20px);
          }
        }
      }

      &[open] summary {
        margin-bottom: 24px;

        &::after {
          background-image: url(../../../images/svg/icon-chevron-up.svg);
        }
      }
    }


  }

  .synonyms {
    margin-top: 24px;
    padding-inline: var(--grape-padding);
    font-size: var(--font-size-small);

    strong:first-of-type::before {
      content: ' ';
    }

    strong:not(:last-of-type)::after {
      content: ', ';
    }
  }

  ul {
    line-height: 1.2;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.grapeType {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: var(--font-size-smaller);
  font-weight: bold;
  margin-bottom: 8px;

  &.blue {
    color: #0C0D7C;
  }

  &.green {
    color: #627E1B;
  }
}
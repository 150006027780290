.skiplinks {
    position: absolute;

    @media print {
        display: none;
    }
}

.link {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    padding: 5px;
    display: block;

    &:focus {
        transform: translateY(0);
        color: var(--color-white);
        z-index: 11;
        background-color: black;
        border-radius: 3px;
    }
}
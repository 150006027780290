.margin-bottom {
    margin-bottom: 16px;

    @media (--from-xlarge) {
        margin-bottom: 24px;
    }
}

.margin-bottom-none {
    margin-bottom: 0;
}

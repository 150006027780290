/*************   Varegrupper ***************/
.header-category-bar {
    --header-category-bar-v-padding: 16px;
    --header-category-bar-font-size: 14px;
    padding: var(--header-category-bar-v-padding) 0;
    transform: translateY(0);
    transition: transform 0.2s ease-out;
    background-color: var(--color-white);
    box-shadow: var(--header-shadow);

    @media @from-large {
        --header-category-bar-v-padding: 20px;
        --header-category-bar-font-size: 16px;
    }

    &.scroll-hidden:not(:focus-within) {
        transform: translateY(-100%);
    }

    .primary-navigation {
        padding: 0 calc(16px + env(safe-area-inset-left));

        @media @from-medium {
            padding: 0;
        }

        /* Varemeny på linje */
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            background-color: var(--color-white);

            li {
                margin: 0;
                white-space: nowrap;
                display: inline-block;

                &.see-more {
                    border-left: 1px solid var(--color-grey-03);
                    position: relative;
                    padding-left: 16px;

                    @media @from-large {
                        padding-left: 32px;
                    }
                }

                a {
                    .text-primary;
                    font-weight: bold;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    line-height: 100%;
                    font-size: var(--header-category-bar-font-size);

                    &:hover,
                    &:active,
                    &:focus {
                        color: var(--color-blue-stone);
                    }
                }


                /* See more dropdown menu */
                ul {
                    position: absolute;
                    top: calc(var(--header-category-bar-font-size) + var(--header-category-bar-v-padding));
                    right: 0;
                    z-index: 16;
                    background: var(--color-white);
                    border-bottom-left-radius: 2px;
                    border-bottom-right-radius: 2px;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    overflow-y: scroll;
                    scrollbar-color: var(--color-grey-02) transparent;
                    scrollbar-width: 6px;

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: var(--color-grey-02);
                    }

                    a {
                        font-size: 16px;
                        padding: 16px;
                    }

                    .divider {
                        display: block;
                        border-bottom: 1px solid var(--color-grey-02);
                        margin: 4px 16px;
                    }
                }

                a[aria-expanded=true]+ul {
                    display: block;
                }

                a[aria-expanded=false]+ul {
                    display: none;
                }
            }
        }

        /*** Show/hide navigation items based on page width ***/
        .header-category-bar-see-more-item {
            display: flex;
            flex-direction: column;

            /*
             hide the first 10 items in dropdown
             they will be revealed based on the page width
             in the rules below
            */
            &:nth-child(-n + 10) {
                display: none;
            }
        }

        @media (width < 320px) {
            .header-category-bar-item:nth-child(3) {
                display: none;
            }

            .header-category-bar-see-more-item:nth-child(3) {
                display: flex;
            }
        }

        @media (width < 420px) {
            .header-category-bar-item:nth-child(4) {
                display: none;
            }

            .header-category-bar-see-more-item:nth-child(4) {
                display: flex;
            }
        }

        @media (width < 540px) {
            .header-category-bar-item:nth-child(5) {
                display: none;
            }

            .header-category-bar-see-more-item:nth-child(5) {
                display: flex;
            }
        }

        @media (width < 640px) {
            .header-category-bar-item:nth-child(6) {
                display: none;
            }

            .header-category-bar-see-more-item:nth-child(6) {
                display: flex;
            }
        }

        @media (width < 740px) {
            .header-category-bar-item:nth-child(7) {
                display: none;
            }

            .header-category-bar-see-more-item:nth-child(7) {
                display: flex;
            }
        }

        @media (width < 840px) {
            .header-category-bar-item:nth-child(8) {
                display: none;
            }

            .header-category-bar-see-more-item:nth-child(8) {
                display: flex;
            }
        }

        @media (width < 940px) {
            .header-category-bar-item:nth-child(9) {
                display: none;
            }

            .header-category-bar-see-more-item:nth-child(9) {
                display: flex;
            }
        }

        @media (width < 1124px) {
            .header-category-bar-item:nth-child(10) {
                display: none;
            }

            .header-category-bar-see-more-item:nth-child(10) {
                display: flex;
            }
        }
    }
}
.product-guide {
    max-width: 620px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .product-guide__sticky-footer {
        position: sticky;
        bottom: 0;
        background: var(--color-white);
        border-radius: 4px;
        box-shadow: var(--color-grey-01-5) 0px -2px 8px 0px;
        border-top: 1px solid var(--color-grey-01-5);
    }

    .product-guide__btn {
        display: flex;
        justify-content: center;
        margin: 16px auto;
    }

    .product-guide__warning-msg {
        padding-top: 16px;
        text-align: center;
    }

    .facet__list {
        .list-unstyled;

        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;

        &.facet__list--grid {
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            grid-auto-rows: 66px;

            &.facet__list--icons {
                grid-auto-rows: 120px;
            }
        }

        &.facet__list--centered {
            grid-gap: 4px;
        }
    }

    .expanded.facet__list-expandable {
        padding-bottom: 16px;
    }

    .facet__list-expandable {
        padding: 0 16px;
        margin-bottom: 16px;
        border-radius: 4px;
    }

    .expandable__header--disabled {

        &:focus,
        &:hover {
            span {
                text-decoration: none;
            }
        }
    }
}